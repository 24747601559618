/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-self-compare */
import { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Typography,
  Upload,
  notification,
  Progress
} from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { ColProps } from 'antd/lib/grid/col';
import { v4 as uuid } from 'uuid';
import {
  ExclamationCircleOutlined,
  HolderOutlined,
  PlusOutlined,
  // UploadOutlined,
} from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import dragula from 'dragula';
import { Auth } from 'aws-amplify';
import './DefineForms.scss';
import '../../App.scss';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { InputStatus } from 'antd/es/_util/statusUtils';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import BoundingBoxOverlay from '../../components/ImageAnnotation';
import { Annotation } from '../../components/ImageAnnotation/types';
import { DefinedForm } from '../../interfaces/DefinedFormType';
import { AppDispatch, RootState } from '../../store/store';
import {
  fetchForms,
  uploadForm,
  updateTemplateFields,
  updateImageSrc,
  setIsHighlightedField,
  resetTemplateFields,
  setLoading,
  resetFormError,
  updateFormStatus,
  setRetryStatus,
} from '../../store/forms/formsReducer';
import { Record } from '../../interfaces/Record';
import fieldsApiService, {
  ScannedFieldResponseData,
} from '../../api/fieldsApi.service';
import { ApiResponse } from '../../interfaces/ApiResponse';
import {
  formsApi,
  TemplateOutput,
  TemplateTypeOutput,
} from '../../api/formsApi.service';
// disabled until we know if we need this
// import ZoomControl from '../../components/PdfView/components/ZoomControl';
import LOCALIZATION from '../../localization';
import { SelectOptions } from '../../interfaces/SelectOptions';
import { resetFields } from '../../store/fields/fieldsReducer';
import RowContextMenu from './components/RowContextMenu';
import { ConfigType } from '../../api/configTypesApi.service';
import UnsavedChangesContext from '../../context/UnsavedChanges';
import FormSelector from '../../components/DefineFormsView/FormSelector';
import DropdownAddingNewType from './components/DropdownAddingNewType';
import { addNewType } from '../../store/formTypes/formTypesReducer';
import PageLoader from '../../components/PageLoader';
import AccessDeniedContext from '../../context/AccessDenied';
import PageHeader from '../../components/PageHeader/PageHeader';

const DEFINE_FORMS_FORM_NAMES: { [key: string]: NamePath } = {
  SELECT_TYPE: 'selectType',
  SELECT_FORM: 'selectTemplate',
  FORM_NAME: 'templateName',
};

// replace all non-alphanumeric characters with an empty string
const filterSpecialChars = (input: string): string => {
  const regex = /[^a-zA-Z0-9]/g;
  const removeSpecialChars = input.replace(regex, '');
  const removeSpaces = removeSpecialChars.replace(/\s+/g, '');
  return removeSpaces.toUpperCase();
};

export const HeaderCol = styled(Col)<{ nopadding?: boolean }>`
  background-color: var(--color-neutral-08);
  color: var(--color-neutral-04);
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  padding-left: ${(props) => (props.nopadding ? '0px' : '15px')};
  padding-right: ${(props) => (props.nopadding ? '0px' : '10px')};
`;

export const FooterCol = styled(Col)`
  background-color: var(--color-white);
  border-top: 1px solid var(--color-neutral-07);
  display: flex;
  height: 36px;
  justify-content: space-between;
  line-height: 36px;
  padding: 5px;
`;

export const FormLabelText = styled(Typography.Text)<{ color?: string }>`
  color: var(--color-black);
  font-size: 1rem;
  font-weight: 400;
`;
const FormTypeInfo = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: var(--color-neutral-04);
  margin-left: 9px;
`;

interface StyledColProps extends ColProps {
  marginleft?: boolean;
}

export const StyledCol = styled(Col)<StyledColProps>`
  padding-left: 15px;
  padding-top: 5px;
  padding-right: 15px;
  ${({ marginleft }) => marginleft && 'margin-left: auto;'}
  ${({ marginleft }) => marginleft && 'padding-right: 10px;'}
`;

export const OkButton = styled(Button).attrs(() => ({
  type: 'primary',
  size: 'middle',
}))`
  width: 220px;
`;
const NoFormMessage = styled.div`
  margin: auto;
  margin-top: 25%;
  color: var(--color-neutral-04);
`;

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  color: var(--color-warning);
  display: inline-block;
`;

const UploadButton = styled(Button)`
  box-sizing: border-box;
  width: 332px;
  background: var(--color-white);
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  margin-top: 10px;
  font-family: 'Verdana';
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  &:hover {
    & .icon-img {
      background-image: url(/icons/upload-hover.svg);
    }
  }
`;

export const RedAsterisk = styled.span`
  color: red;
  margin-right: 2px;
`;

const ImgDiv = styled.div`
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-image: url(/icons/upload-icon.svg);
`;

const CloseCircle = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  background-image: url(/icons/close-circle.svg);
`;

const isNumeric = (value: string) => /^\d+$/.test(value);

const getIndexInParent = (el: any) =>
  Array.from(el.parentNode.children).indexOf(el);

function DefineForms() {
  const [api, contextHolder] = notification.useNotification();

  const [defineFormsForm] = Form.useForm<DefinedForm>();
  const watchedFormName = Form.useWatch(
    DEFINE_FORMS_FORM_NAMES.FORM_NAME,
    defineFormsForm
  );
  const selectedFormType = Form.useWatch(
    DEFINE_FORMS_FORM_NAMES.SELECT_TYPE,
    defineFormsForm
  );

  const [filteredFormTemplates, setFilteredFormTemplates] = useState<
    SelectOptions[]
  >([]);
  const [current, setCurrent] = useState({});
  const [selectedFormId, setSelectedFormId] = useState<string>('');
  const [originalSelectedFormName, setOriginalSelectedFormName] =
    useState<string>('');
  const [fileList, setFileList] = useState<Array<UploadFile>>([]);
  // const [uploadedFileList, setUploadedFileList] = useState<Array<UploadFile>>([]);

  const [isFormNameUnique, setIfFormNameIsUnique] = useState<boolean>(true);
  const [previousErrorMessage, setPreviousErrorMessage] = useState<string>('');

  const [selectedRowRecord, setSelectedRowRecord] = useState<Record>();

  const [submitButtonEnabled, setSubmitButtonEnabled] =
    useState<boolean>(false);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);

  const [addFieldButtonEnabled, setAddFieldButtonEnabled] =
    useState<boolean>(true);

  const [majorChangeFlag, setMajorChangeFlag] = useState<boolean>(false);
  const [minorChangeFlag, setMinorChangeFlag] = useState<boolean>(false);
  const [newTemplateFlag, setNewTemplateFlag] = useState<boolean>(true);
  const [fileUploadPercentage, setFileUploadPercentage] = useState<number>(25);
  const tempRef = useRef<boolean>();
  tempRef.current = newTemplateFlag;

  const [userWasSureBefore, setIfUserWasSureBefore] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const formTypesStoreList = useSelector(
    (state: RootState) => state.formTypes.list
  );
  const formTypesStoreStatus = useSelector(
    (state: RootState) => state.formTypes.status
  );
  const formTypesStoreError = useSelector(
    (state: RootState) => state.formTypes.error
  );
  const [formTypesOptions, setFormTypesOptions] = useState<
    { label: string; value: string }[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tempFormType, setTempFormType] = useState<{
    label: string;
    value: string;
  }>({ value: '', label: '' });

  const formsStoreList = useSelector((state: RootState) => state.forms.list);
  const formsStoreStatus = useSelector(
    (state: RootState) => state.forms.status
  );
  const formsStoreError = useSelector((state: RootState) => state.forms.error);
  const formsStoreRevision = useSelector(
    (state: RootState) => state.forms.revision
  );

  const templateFields = useSelector(
    (state: RootState) => state.forms.templateFields
  );
  const analyzedFormDetails = useSelector(
    (state: RootState) => state.forms.formDetails
  );
  const templateFieldsRef = useRef<Record[]>([]);

  const boundingBoxRef = useRef<HTMLDivElement>(
    null as unknown as HTMLDivElement
  );

  const configTypesStoreList = useSelector(
    (state: RootState) => state.configTypes.list
  );

  const imageSrc = useSelector((state: RootState) => state.forms.imageSrc);
  const imgS3Url = useSelector((state: RootState) => state.forms.imgS3Url);

  const fieldsStoreStatus = useSelector(
    (state: RootState) => state.forms.status
  );

  const [imagePath, setImagePath] = useState<string>('');

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAdvisoryModal, setShowAdvisoryModal] = useState<boolean>(false);

  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalText, setModalText] = useState<string>('');

  const [recordOnHold, setRecordOnHold] = useState<Record>();
  const [annotationOnHold, setAnnotationOnHold] = useState<Annotation>();
  const [highlightedOnHold, setHighlightedOnHold] = useState<boolean>(false);
  const isAdmin = useSelector((state: RootState) => state.user.isAdmin);
  const loading = useSelector(
    (state: RootState) => (state.forms.status === 'loading' || state.forms.status === 'in-progress')
  );
  const { Option } = Select;
  const [options, setOptions] = useState<any>();
  const [uploadedFormPages, setUploadedFormPages] = useState<any>([]);
  const [pageNosToBeScanned, setPageNosToBeScanned] = useState<any>([]);
  const [activeKey, setActiveKey] = useState("new");
  const [multiFileUrl, setMultiFileUrl] = useState("");
  const [tryUploadApi, setTryUploadApi] = useState(0);
  const retryStatus = useSelector((state: RootState) => state.forms.retryStatus);
  const [redrawnIds, setRedrawnIds] = useState<string[]>([]);
  const [allTemplateFields, setAllTemplateFields] = useState<any[]>([]);
  const [tempHeight, setTempHeight] = useState<any>(1);
  const [updatingTemplate, setUpdatingTemplate] = useState(false);
  
  // const newTemplateContent =
  const formTabs = [
    {
      id: 'new',
      label: LOCALIZATION.DEFINE_FORMS_RADIO_1_LABEL,
      // children: newTemplateContent
    },
    {
      id: 'edit',
      label: LOCALIZATION.DEFINE_FORMS_RADIO_2_LABEL,
    },
  ];

  const {
    markUnsavedChanges,
    markSavedChanges,
    unsavedChanges,
    setTriggerCallback,
    changeTrigger,
    setCleanUpCallback,
  } = useContext(UnsavedChangesContext);
  const { isAccessDenied, triggerAccessDeniedModal } = useContext(AccessDeniedContext);
  const [uploadFormParams, setUploadFormParams] = useState({ formBase64: "", imageExt: "", pageNo: '1' });
  const transactionId = useSelector((state: RootState) => state.forms.transactionId);

  const [rowContextMenu, setRowContextMenu] = useState<{
    visible: boolean;
    x: number;
    y: number;
  }>({
    visible: false,
    x: 0,
    y: 0,
  });

  const [formTypeSearchValue, setFormTypeSearchValue] = useState<string>('');

  const showFormTemplateFields =
    (newTemplateFlag && imageSrc && selectedFormType) ||
    (!newTemplateFlag && selectedFormType);

  const showFormTemplateName =
    (newTemplateFlag && selectedFormType) ||
    (!newTemplateFlag && selectedFormType && majorChangeFlag);

  /**
   * Clean up previously set values and put screen back at the beginning
   */
  const cleanUp = () => {
    dispatch(resetTemplateFields());
    setFilteredFormTemplates(() => []);
    setUploadedFormPages([]);
    setPageNosToBeScanned([]);
    setRedrawnIds([]);
    setMultiFileUrl("");
    setSelectedFormId(() => '');
    setMinorChangeFlag(() => false);
    setMajorChangeFlag(() => false);
    dispatch(resetFields());
    dispatch(updateTemplateFields([]));
    dispatch(updateImageSrc(''));
    setAnnotationOnHold(() => null as unknown as Annotation);
    setRecordOnHold(() => null as unknown as Record);
    defineFormsForm.resetFields([
      DEFINE_FORMS_FORM_NAMES.SELECT_TYPE,
      DEFINE_FORMS_FORM_NAMES.SELECT_FORM,
      DEFINE_FORMS_FORM_NAMES.FORM_NAME,
    ]);
    setFileList([]);
    setIfUserWasSureBefore(() => false);
  };

  /**
   * Handle selection of value from form/template dropdown
   * Will untoggle new template flag and will fetch fields for specified form
   */
  const onFormSelect = async () => {
    const getFromFormSelectedFormId = defineFormsForm.getFieldValue(
      DEFINE_FORMS_FORM_NAMES.SELECT_FORM
    );
    setSelectedFormId(() => getFromFormSelectedFormId);
    setMajorChangeFlag(() => false);
    setMinorChangeFlag(() => false);
    dispatch(updateImageSrc(''));
    dispatch(updateTemplateFields([]));
    setRedrawnIds([]);
    setAnnotationOnHold(() => null as unknown as Annotation);
    setRecordOnHold(() => null as unknown as Record);
    setIfUserWasSureBefore(() => false);

    const selectedForm = formsStoreList.find(
      (form) => form.id === getFromFormSelectedFormId
    );
    if (selectedForm) {
      defineFormsForm.setFieldValue(
        DEFINE_FORMS_FORM_NAMES.FORM_NAME,
        selectedForm.formName
      );
      setOriginalSelectedFormName(() => selectedForm.formName);

      const fieldResponse: ApiResponse = await fieldsApiService.getByFormId(
        selectedFormType,
        selectedForm?.formName
      );
      if(!tempRef.current) {
        if (fieldResponse.results.length > 0) {
          setImagePath(() => fieldResponse.results[0].imagePath);
          dispatch(
            updateTemplateFields(
              fieldResponse.results[0].fields
                .map((field: ScannedFieldResponseData) => ({
                  confidence: field.boundingBox?.confidence,
                  geometry: field.boundingBox?.geometry,
                  id: field.id.length > 0 ? field.id : uuid(),
                  name: field.boundingBox?.name,
                  type: field.boundingBox?.type,
                  isRequired: field.isRequired,
                  isHighlighted: false,
                  pageY: field.pageY || 1,
                  page: field.page || 0,
                  page_geometry: field.boundingBox?.page_geometry,
                }))
                .sort(
                  (a: ScannedFieldResponseData, b: ScannedFieldResponseData) =>
                    (a.boundingBox?.geometry.y as number) -
                    (b.boundingBox?.geometry.y as number)
                )
            )
          );
          dispatch(updateImageSrc(fieldResponse.results[0].imagePresignedUrl));
        } else if (fieldResponse.message && fieldResponse.message.length > 0) {
          api.destroy();
          api.error({
            message: LOCALIZATION.ERROR_OCCURED_TITLE,
            description: LOCALIZATION.ERROR_OCCURED_HTTP_MESSAGE.replace(
              ':ERROR',
              fieldResponse.message as string
            ),
            placement: 'bottomLeft',
          });
        }

        setFileList([]);
        setNewTemplateFlag(() => false);
      }
    }
  };

  /**
   * Handle click on annotation in bounding box overlay
   * @param annotation
   */
  const onChange = (annotation: Annotation) => {
    setCurrent(annotation);
  };

  /**
   * Check if there is a field with empty bounding box
   */
  const areThereEmptyBoundingBoxes = () =>
    templateFields.some(
      (tf: Record) =>
        tf.geometry.x +
          tf.geometry.y +
          tf.geometry.height +
          tf.geometry.width <=
        0
    );

  /**
   * Check if there is a field with empty name
   */
  const areThereFieldsWithoutName = () =>
    templateFields.some((tf: Record) => tf.name.length === 0);

  /**
   * Check if user can draw a bounding box
   */
  const canDrawBoundingBox = () =>
    templateFields.some(
      (tf: Record) =>
        tf.geometry.x +
          tf.geometry.y +
          tf.geometry.height +
          tf.geometry.width <=
        0
    );

  /**
   * Fetch fields table row elements and ref
   */
  const fetchFieldsTableRef = () => {
    try {
      // Antd Table does not support ref...
      const fieldsTableRef = document.querySelector(
        '.DefineForms-table div.ant-table-body'
      ) as Element;
      const tableRowsEls = fieldsTableRef.children[0].children[1].children;
      return { fieldsTableRef, tableRowsEls };
    } catch (error) {
      console.warn('Fields table ref not working as expected.', error);
      return {
        fieldsTableRef: null as unknown as Element,
        tableRowsEls: [] as unknown as HTMLCollection,
      };
    }
  };

  /**
   * Handle scroll to corresponding row in fields table
   * @param record
   */
  const scrollToFieldRow = (record: Record) => {
    const templateFieldsIndex = templateFields.findIndex(
      (field: Record) => field.id === record.id
    );
    try {
      const { fieldsTableRef, tableRowsEls } = fetchFieldsTableRef();
      const tableRows = Array.from(tableRowsEls).filter(
        (value: Element, index: number) =>
          index > 0 && index <= templateFieldsIndex
      );
      const scrollToHeight = tableRows.reduce(
        (prevHeight: number, element: Element) =>
          prevHeight + element.getBoundingClientRect().height,
        0
      );

      fieldsTableRef.scrollTo({
        top: scrollToHeight,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      console.warn('Fields table ref not working as expected.', error);
    }
  };

  /**
   * Scroll to the top of fields table
   */
  const scrollToTheTopFieldRow = () => {
    try {
      const { fieldsTableRef } = fetchFieldsTableRef();

      fieldsTableRef.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      console.warn('Fields table ref not working as expected.', error);
    }
  };

  /**
   * Trigger a warning about missing bounding box
   */
  const triggerMissingBoundingBoxWarning = () => {
    if (areThereEmptyBoundingBoxes()) {
      api.destroy();
      api.warning({
        message: LOCALIZATION.DEFINE_FORMS_BB_MISSING_TEXT_TITLE,
        description: LOCALIZATION.DEFINE_FORMS_BB_MISSING_TEXT_SHORT,
        placement: 'bottomLeft',
        icon: <StyledExclamationCircleOutlined />,
      });
    }
  };

  /**
   * Handles click on annotation in bounding box overlay
   * and select corresponding record from fields table
   * @param e
   * @param relativeMousePos
   */
  const onBoxSelection = (
    e: React.MouseEvent,
    relativeMousePos: { x: number; y: number }
  ) => {
    triggerMissingBoundingBoxWarning();

    const { x, y } = relativeMousePos;
    const matchingTemplateFields = templateFields
      .filter(
        (tf: Record) =>
          x >= tf.geometry.x * 100 &&
          x <= tf.geometry.x * 100 + tf.geometry.width * 100
      )
      .filter(
        (tf: Record) =>
          y >= tf.geometry.y * 100 * (tf.pageY || 1) &&
          y <= tf.geometry.y * 100 * (tf.pageY || 1) + tf.geometry.height * 100 * ((tf.pageY === 1 || !tf.pageY) ? 1 : tempHeight)
      );
    if (matchingTemplateFields.length > 0) {
      const templateFieldToHighlight = matchingTemplateFields[0];

      if (!areThereEmptyBoundingBoxes() && !areThereFieldsWithoutName()) {
        dispatch(setIsHighlightedField(templateFieldToHighlight));
        setSelectedRowRecord(() => templateFieldToHighlight);
        scrollToFieldRow(templateFieldToHighlight);
      }
    }
  };

  /**
   * Handle a creation of new bounding box in bounding box overlay
   * Will toggle major change flag
   * @param annotation
   */
  const onSubmit = (annotation: Annotation) => {
    const { geometry } = annotation;
    setShowAdvisoryModal(() => false);
    setCurrent({});

    api.destroy();

    if (selectedRowRecord) {
      const foundSelectedPairRecordIndex = templateFields.findIndex(
        (record: Record) => record.id === selectedRowRecord.id
      );
      const foundSelectedPairRecord =
        templateFields[foundSelectedPairRecordIndex];
      if (foundSelectedPairRecord) {
        if (
          foundSelectedPairRecord.geometry.width === 0 &&
          foundSelectedPairRecord.geometry.height === 0
        ) {
          if (
            foundSelectedPairRecord.geometry.x <= 0 &&
            foundSelectedPairRecord.geometry.y <= 0
          ) {
            const yVal = geometry ? (geometry?.y as number) / 100 : 0;
            let textactedPages = [...new Set(allTemplateFields?.map(i => i.page))];
            textactedPages = textactedPages.sort((a, b) => a - b);
            let page = 0;
            // eslint-disable-next-line no-restricted-syntax
            for (let p = 0; p < textactedPages.length; p++) {
              if (yVal > p * tempHeight) {
                page = textactedPages[p];
              }
            }
            const redrawnFieldRecord = {
              ...foundSelectedPairRecord,
              geometry: {
                width: geometry ? (geometry?.width as number) / 100 : 0,
                height: geometry ? (geometry?.height as number) / (100 * ((foundSelectedPairRecord.pageY === 1 || !foundSelectedPairRecord.pageY) ? 1 : tempHeight)) : 0,
                x: geometry ? (geometry?.x as number) / 100 : 0,
                y: geometry ? (geometry?.y as number) / (100 * (foundSelectedPairRecord.pageY || 1)) : 0,
              },
              page_geometry: {
                width: geometry ? (geometry?.width as number) / 100 : 0,
                height: geometry ? (geometry?.height as number) / (100 * ((foundSelectedPairRecord.pageY === 1 || !foundSelectedPairRecord.pageY) ? 1 : tempHeight)) : 0,
                x: geometry ? (geometry?.x as number) / 100 : 0,
                y: geometry ? (geometry?.y as number) / (100 * (foundSelectedPairRecord.pageY || 1)) : 0,
              },
              page,
            };
            const alreadyRedrawn = redrawnIds.some(i => i === redrawnFieldRecord.id);
            if (!alreadyRedrawn) setRedrawnIds((prev) => ([...prev, redrawnFieldRecord.id]));
            
            const fieldsToDispatch = templateFields.filter(
              (record: Record) => record.id !== foundSelectedPairRecord.id
            );
            fieldsToDispatch.splice(
              foundSelectedPairRecordIndex,
              0,
              redrawnFieldRecord
            );
            dispatch(updateTemplateFields(fieldsToDispatch));
            markUnsavedChanges();
          }
        }
      }
    }
  };

  /**
   * Create a blank record after click on + button in fields table header
   */
  const createBlankRecord = () => {
    const name = 'Field Name';
    let newFieldsCounter = 0;

    templateFields.forEach((tf: Record) => {
      if (tf.name.includes(name)) {
        newFieldsCounter++;
      }
    });

    const newFieldRecord: Record = {
      id: uuid(),
      name: `${name} ${
        newFieldsCounter > 0 ? `(${newFieldsCounter + 1})` : ''
      }`,
      type: 'freetext',
      confidence: 100,
      geometry: {
        height: 0,
        width: 0,
        x: -10,
        y: -10,
      },
      page_geometry: {
        height: 0,
        width: 0,
        x: -10,
        y: -10,
      },
      order: 0,
      pageY: 1,
      isHighlighted: true,
    };
    const newFields: Array<Record> = [newFieldRecord].concat(
      templateFields.map((tf: Record) => ({
        ...tf,
        isHighlighted: false,
      }))
    );

    dispatch(updateTemplateFields(newFields));
    setSelectedRowRecord(newFields[0]);
    setMajorChangeFlag(() => true);
    markUnsavedChanges();
    scrollToTheTopFieldRow();
  };

  /**
   * Show advisory modal after click on add field button
   */
  const showCreateBlankRecordAdvisory = () => {
    setModalTitle(() => LOCALIZATION.ARE_YOU_SURE_TITLE);
    setModalText(() => LOCALIZATION.DEFINE_FORMS_ADVISORY_ADD_TEXT);
    if (!newTemplateFlag && !userWasSureBefore) {
      setShowAdvisoryModal(() => true);
    } else {
      createBlankRecord();
    }
  };

  /**
   * Store annotation that was set to delete, but is waiting confirmation by modal
   * @param annotation
   */
  // const onAnnotationHoldForSubmit = (annotation: Annotation) => {
  //   setAnnotationOnHold(() => annotation);

  //   // setModalTitle(() => LOCALIZATION.ARE_YOU_SURE_TITLE);
  //   // setModalText(() => LOCALIZATION.DEFINE_FORMS_ADVISORY_ADD_TEXT);
  //   // if(!newTemplateFlag) {
  //   //   setShowAdvisoryModal(() => true);
  //   // } else {
  //   //   onSubmit(annotation);
  //   // }
  //   onSubmit(annotation);
  // }

  /**
   * Will cancel selection of bounding box
   */
  const onCancel = () => {
    setCurrent({});
  };

  /**
   * Handles click on row in fields table and will highlight corresponding bounding box
   * @param record
   */
  const handleHighlight = (record: Record) => {
    const newFields = templateFields.map((field: Record) => {
      if (field.id === record.id) {
        return {
          ...field,
          isHighlighted: true,
        } as Record;
      }
      return {
        ...field,
        isHighlighted: false,
      } as Record;
    });

    dispatch(updateTemplateFields(newFields));
    markUnsavedChanges();
  };

  /**
   * Handles change of type of field in fields table
   * @param record
   * @param value
   */
  const handleTypeChange = (record: Record, value: string) => {
    const newFields = templateFields.map((field: Record) => {
      if (field.id === record.id) {
        return {
          ...field,
          type: value,
        } as Record;
      }

      return field;
    });

    dispatch(updateTemplateFields(newFields));
    markUnsavedChanges();
  };

  /**
   * Handles change of field's confidence threshold
   * @param record
   * @param e
   * @returns
   */
  const handleConfidenceChange = (
    record: Record,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let { value } = e.target;
    // allow only numbers as input
    if (!isNumeric(value) && value !== '') {
      return;
    }

    if (parseInt(value, 10) > 100) {
      value = 100 as unknown as string;
    }
    if (parseInt(value, 10) < 0) {
      value = 0 as unknown as string;
    }

    const numericValue: number = value === '' ? 0 : parseInt(value, 10);

    const newFields = templateFields.map((field: Record) => {
      if (field.id === record.id) {
        return {
          ...field,
          confidence: numericValue,
        } as Record;
      }

      return field;
    });

    dispatch(updateTemplateFields(newFields));
    markUnsavedChanges();
  };

  /**
   * Handles change of field's name/key
   * @param record
   * @param e
   */
  const handleNameChange = (
    record: Record,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newFields = templateFields.map((field: Record) => {
      if (field.id === record.id) {
        return {
          ...field,
          name: e.target.value,
        } as Record;
      }

      return field;
    });

    dispatch(updateTemplateFields(newFields));
    markUnsavedChanges();
  };

  /**
   * Handle change of isRequired value of field
   * @param record
   * @param isRequired
   */
  const handleIsRequiredChange = (record: Record, isRequired: boolean) => {
    const newFields = templateFields.map((field: Record) => {
      if (field.id === record.id) {
        return {
          ...field,
          isRequired,
        } as Record;
      }

      return field;
    });

    dispatch(updateTemplateFields(newFields));
    markUnsavedChanges();
  };

  /**
   * Handles deletion of bounding box
   * @param record 
  
  */
  const handleDelete = (record: Record) => {
    const newFields = templateFields.map((field: Record) => {
      if (field.id === record.id) {
        const newField = {
          ...field,
          geometry: {
            width: 0,
            height: 0,
            x: -10,
            y: -10,
          },
          page_geometry: {
            width: 0,
            height: 0,
            x: -10,
            y: -10,
          },
        };
        return newField;
      }
      return field;
    });

    setMinorChangeFlag(() => true);
    dispatch(updateTemplateFields(newFields));
    markUnsavedChanges();
    setShowAdvisoryModal(() => false);
  };

  /**
   * Handles deletion of field from fields table
   * @param record
   */
  const handleRowDelete = (record: Record = null as unknown as Record) => {
    setMajorChangeFlag(() => true);

    let updatedFields = [];
    if (record === null) {
      updatedFields = templateFields.filter((field) => !field.isHighlighted);
    } else {
      updatedFields = templateFields.filter((field) => field.id !== record.id);
    }
    dispatch(updateTemplateFields(updatedFields));
    markUnsavedChanges();
  };

  const onAdvisoryModalOk = () => {
    if (annotationOnHold) {
      onSubmit(annotationOnHold);
      setAnnotationOnHold(() => null as unknown as Annotation);
      setShowAdvisoryModal(() => false);
      return;
    }

    if (recordOnHold) {
      handleDelete(recordOnHold);
      handleRowDelete(recordOnHold);
      setRecordOnHold(() => null as unknown as Record);
      setHighlightedOnHold(() => false);
      setShowAdvisoryModal(() => false);
      return;
    }

    if (highlightedOnHold) {
      handleRowDelete();
      setHighlightedOnHold(() => false);
      setShowAdvisoryModal(() => false);
      return;
    }

    createBlankRecord();
    setShowAdvisoryModal(() => false);
  };

  const onRecordSelect = (record: Record) => {
    if (!areThereEmptyBoundingBoxes() && !areThereFieldsWithoutName()) {
      boundingBoxRef.current.scrollTo({
        top: record.geometry.y * (record.pageY || 1) * boundingBoxRef.current.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
      setSelectedRowRecord(() => record);
      dispatch(setIsHighlightedField(record));

      if (!newTemplateFlag && isAdmin) {
        markUnsavedChanges();
      }
    }
    triggerMissingBoundingBoxWarning();
  };

  /**
   * Handles changes in order of fields done by drag and drop
   * @param dragIndex
   * @param draggedIndex
   */
  const handleReorder = (dragIndex: number, draggedIndex: number) => {
    const doReorder = () => {
      let tempTemplateFields = templateFields;
      if (templateFields.length === 0) {
        tempTemplateFields = templateFieldsRef.current;
      }
      const reordered = [
        ...tempTemplateFields.map((field: Record) => {
          const nothighlightedField = { ...field };
          nothighlightedField.isHighlighted = false;
          return nothighlightedField;
        }),
      ];

      const item = reordered.splice(dragIndex, 1)[0];
      const highlightedItem = {
        ...item,
        isHighlighted: true,
      };

      onRecordSelect(highlightedItem);

      reordered.splice(draggedIndex, 0, highlightedItem);
      return reordered;
    };

    setMinorChangeFlag(() => true);
    dispatch(updateTemplateFields(doReorder()));
    markUnsavedChanges();
  };

  /**
   * Do actions following confirmation on submit prompt
   */
  const doHandleDefineFormsFormSubmit = async () => {
    // this is eliminate delay in showing loading indicator
    dispatch(setLoading());
    setUpdatingTemplate(true);
    setShowModal(() => false);
    setShowAdvisoryModal(() => false);
    setAnnotationOnHold(() => null as unknown as Annotation);
    setRecordOnHold(() => null as unknown as Record);

    let formNameUniquness = true;
    const allFormsResponse: ApiResponse = await formsApi.getAll();
    if (allFormsResponse!.message) {
      if (
        allFormsResponse.results.some(
          (form: TemplateOutput) =>
            form.formName.toUpperCase() ===
              (watchedFormName || '').toUpperCase() &&
            form.id !== selectedFormId &&
            form.formType === selectedFormType
        )
      ) {
        formNameUniquness = false;
      }
    } else if (
      formsStoreList.some(
        (form) =>
          form.formName.toUpperCase() ===
            (watchedFormName || '').toUpperCase() &&
          form.id !== selectedFormId &&
          form.formType === selectedFormType
      )
    ) {
      formNameUniquness = false;
    }

    setIfFormNameIsUnique(() => formNameUniquness);

    if (!formNameUniquness) {
      setPreviousErrorMessage(() => LOCALIZATION.NOT_UNIQUE_FORM_NAME_MESSAGE);
    } else {
      setPreviousErrorMessage(() => '');

      let getImagePath = imagePath;

      if (newTemplateFlag) {
        const data = await formsApi.saveImage(
          selectedFormType,
          watchedFormName,
          imgS3Url
        );
        getImagePath = data.newImageSrc;

        if (data.message && data.message.length > 0) {
          api.destroy();
          api.error({
            message: LOCALIZATION.ERROR_OCCURED_TITLE,
            description: LOCALIZATION.ERROR_OCCURED_HTTP_MESSAGE.replace(
              ':ERROR',
              data.message as string
            ),
            placement: 'bottomLeft',
          });
        }
      }

      const putResponse = await formsApi.putTemplateByName(
        selectedFormType,
        watchedFormName,
        {
          majorVersion: majorChangeFlag,
          templateName: watchedFormName,
          originalTemplateName: originalSelectedFormName,
          formType: selectedFormType,
          imagePath: getImagePath,
          selectedPages: analyzedFormDetails?.selectedPages || [0],
          redrawnIds,
          fields: templateFields.map((field: Record) => ({
            id: field.id,
            fieldName: field.name,
            requiredType: field.type,
            isRequired: field.isRequired,
            confidence: field.confidence,
            pageY: field.pageY || 1,
            page: field.page || 0,
            boundingBox: {
              geometry: field.geometry,
              page_geometry: field.page_geometry,
            },
          })),
        }
      );

      api.destroy();
      if (putResponse.message && putResponse.message.length > 0) {
        api.error({
          message: LOCALIZATION.ERROR_OCCURED_TITLE,
          description: LOCALIZATION.ERROR_OCCURED_HTTP_MESSAGE.replace(
            ':ERROR',
            putResponse.message as string
          ),
          placement: 'bottomLeft',
        });
      } else {
        if (newTemplateFlag) {
          api.success({
            message: LOCALIZATION.DEFINE_FORMS_FORM_SUBMIT_OK_TITLE,
            description: LOCALIZATION.DEFINE_FORMS_NEW_FLOW_TASK.replace(
              ':FORMTYPE',
              selectedFormType
            ),
            placement: 'bottomLeft',
          });
        } else {
          api.success({
            message: LOCALIZATION.DEFINE_FORMS_FORM_SUBMIT_OK_TITLE,
            description: majorChangeFlag
              ? LOCALIZATION.DEFINE_FORMS_FORM_SUBMIT_OK_MAJOR_MESSAGE
              : LOCALIZATION.DEFINE_FORMS_FORM_SUBMIT_OK_MINOR_MESSAGE,
            placement: 'bottomLeft',
          });
        }
        setUpdatingTemplate(false);
        markSavedChanges();
        // setNewTemplateFlag(true);
        dispatch(fetchForms());
        cleanUp();
      }
    }
  };

  /**
   * Does shallow check if template name is unique, by comparison with content of app's store
   * @returns
   */
  const shallowCheckIfTemplateNameIsUnique = (templateName: string) => {
    let formNameIsUnique = true;
    setPreviousErrorMessage(() => '');

    if (majorChangeFlag || minorChangeFlag || newTemplateFlag) {
      if (
        formsStoreList.some(
          (form) =>
            form.formName.toUpperCase() ===
              (templateName || '').toUpperCase() &&
            form.formType === selectedFormType
        )
      ) {
        formNameIsUnique = false;
        setPreviousErrorMessage(
          () => LOCALIZATION.NOT_UNIQUE_FORM_NAME_MESSAGE
        );
      }
    }

    setIfFormNameIsUnique(() => formNameIsUnique);

    return formNameIsUnique;
  };

  const setUploadProgress = () => {
    for (let i = 1; i <= 3; i++) {
      if (fileUploadPercentage !== 100) {
        setTimeout(() => {
          setFileUploadPercentage(25 * i);
        }, 1000 * i);
      }
    }
  };

  const readFileAsDataURL = (file: File): Promise<string | null> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result.split(',')[1]);
        } else {
          reject(new Error('Failed to read file as data URL'));
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  /**
   * Handles upload of sample scan
   * According to state of upload, displays spinner
   * Sets app for creation of new template and toggles major change flag
   * @param info
   */

  const analyzeForm = async (_options?: any) => {
    const file = _options ? _options.file : options.file;
    const formOptions = _options || options;
    setFileList([file]);
    setUploadedFormPages([]);
    setPageNosToBeScanned([]);
    setMultiFileUrl("");
    dispatch(setRetryStatus(true));
    try {
      const formBase64 = await readFileAsDataURL(file);
      const imageName = file.name;
      // const isImage = imageName.match(/\.(jpeg|jpg|png)$/) !== null;

      const params: any = {
        formBase64,
        imageExt: imageName.split('.').pop()?.toLowerCase(),
        pageNo: pageNosToBeScanned.toString() || '1',
      };
      setUploadFormParams(params);

      dispatch(uploadForm(params));
      markUnsavedChanges();
      setUploadProgress();
      formOptions.onSuccess();
    } catch (error) {
      setFileList([]);
      formOptions.onError(error);
    }
  }
  
  const handleCustomRequest = (_options: any) => {
    setOptions(_options);
    const { file } = _options;
    const reader: any = new FileReader();
    reader.readAsBinaryString(file);
    reader.onloadend = () => {
      const count = reader.result?.match(/\/Type[\s]*\/Page[^s]/g)?.length || 1;
      if (count > 1) {
        const fileURL = window.URL.createObjectURL(file);
        setMultiFileUrl(`${fileURL}#toolbar=0&navpanes=0&scrollbar=0`);
        let pages: any[] = [];
        for (let i = 1; i <= count; i++) {
          pages = [...pages, i];
        }
        setUploadedFormPages(pages);
        setMajorChangeFlag(() => true);
        markUnsavedChanges();
      } else {
        analyzeForm(_options);
      }
  };
}

  // const handleUploadChange = (info: { fileList: Array<UploadFile> }) => {
  //   setUploadedFileList([...info.fileList]);
  // };

  /**
   * Simple boolean to say if form type is empty and it shouldn't be
   */
  const formTypeIsEmptyAndShouldntBe = () =>
    (selectedFormType || '').length === 0 && (watchedFormName || '').length > 0;

  let updatedFormTypesOptions = formTypesOptions;
  if (newTemplateFlag && tempFormType.value !== '') {
    const existingOption = formTypesOptions.find(
      (option) => option.value === tempFormType.value
    );

    if (!existingOption) {
      updatedFormTypesOptions = [tempFormType, ...formTypesOptions];
    }
  }
  // Form types options
  const generateSelectFormTypeItem = () => {
    const props = {
      id: `DefineForms_Select_${DEFINE_FORMS_FORM_NAMES.SELECT_TYPE}`,
      virtual: false,
      showSearch: true,
      placeholder: LOCALIZATION.SELECT_TYPE_LABEL,
      options: updatedFormTypesOptions,
      size: 'middle' as SizeType,
      value: defineFormsForm.getFieldValue(DEFINE_FORMS_FORM_NAMES.SELECT_TYPE),
      style: { width: '100%' },
      searchValue: formTypeSearchValue,
      loading: formTypesStoreStatus === 'loading',
      status: formTypeIsEmptyAndShouldntBe()
        ? ('error' as InputStatus)
        : ('' as InputStatus),
      onChange: () => {
        const onChangeAction = () => {
          setIfFormNameIsUnique(() => true);
          if (!newTemplateFlag && !submitButtonEnabled && !saveButtonEnabled) {
            dispatch(updateTemplateFields([]));
            dispatch(updateImageSrc(''));
          }
        };
        if (unsavedChanges && !newTemplateFlag) {
          setTriggerCallback(() => {
            onChangeAction();
          });
          changeTrigger();
        } else {
          onChangeAction();
        }
      },
      onSearch: (value: string) => {
        setFormTypeSearchValue(filterSpecialChars(value));
      },
      onSelect: (value: string) => {
        if (submitButtonEnabled || saveButtonEnabled || majorChangeFlag) {
          if (unsavedChanges && !newTemplateFlag) {
            setTriggerCallback(() => {
              cleanUp();
              if (value === 'new-edit-form-select') {
                setNewTemplateFlag(() => !newTemplateFlag);
              } else {
                defineFormsForm.setFieldValue(
                  DEFINE_FORMS_FORM_NAMES.SELECT_TYPE,
                  filterSpecialChars(value)
                );
              }
            });
            changeTrigger();
          } else {
            defineFormsForm.setFieldValue(
              DEFINE_FORMS_FORM_NAMES.SELECT_TYPE,
              filterSpecialChars(value)
            );
          }
        } else {
          defineFormsForm.setFieldValue(
            DEFINE_FORMS_FORM_NAMES.SELECT_TYPE,
            filterSpecialChars(value)
          );
        }
      },
    };

    const propsUpdated = {
      ...props,
      // eslint-disable-next-line react/no-unstable-nested-components
      dropdownRender: (menu: any) => (
        <DropdownAddingNewType
          filterSpecialChars={filterSpecialChars}
          menu={menu}
          onAddNewType={(newItem) => {
            if (
              formTypesStoreList.some(
                (formType: TemplateTypeOutput) => formType.type === newItem
              )
            ) {
              defineFormsForm.setFieldValue(
                DEFINE_FORMS_FORM_NAMES.SELECT_TYPE,
                filterSpecialChars(newItem)
              );
            } else {
              dispatch(addNewType(filterSpecialChars(newItem)));
              defineFormsForm.setFieldValue(
                DEFINE_FORMS_FORM_NAMES.SELECT_TYPE,
                filterSpecialChars(newItem)
              );
            }
          }}
        />
      ),
    };

    return (
      <Form.Item
        label={
          <>
            {isAdmin && <RedAsterisk>*</RedAsterisk>}
            {newTemplateFlag
              ? LOCALIZATION.DEFINE_FORMS_SELECT_TYPE_LABEL
              : LOCALIZATION.SELECT_TYPE_LABEL}
          </>
        }
        name={DEFINE_FORMS_FORM_NAMES.SELECT_TYPE}
      >
        <Tooltip
          title={
            formTypeIsEmptyAndShouldntBe()
              ? LOCALIZATION.DEFINE_FORMS_FORM_TYPE_IS_EMPTY_MESSAGE
              : ''
          }
        >
          {newTemplateFlag && <Select {...propsUpdated} />}
          {!newTemplateFlag && <Select {...props} />}
        </Tooltip>
        {newTemplateFlag && (
          <FormTypeInfo>{LOCALIZATION.FORM_TYPE_SELECT_INFO}</FormTypeInfo>
        )}
      </Form.Item>
    );
  };

  const shouldEnableSubmitButton = () => {
    if (!isAdmin) return false;
    if (!watchedFormName || !isFormNameUnique) return false;
    if (areThereEmptyBoundingBoxes()) return false;
    if (areThereFieldsWithoutName()) return false;

    if (newTemplateFlag && selectedFormType) return true;
    if (!newTemplateFlag && majorChangeFlag) return true;

    return false;
  };

  const shouldEnableSaveButton = () => {
    if (!isAdmin) return false;
    if (areThereEmptyBoundingBoxes() || areThereFieldsWithoutName())
      return false;
    if (!watchedFormName || !isFormNameUnique || newTemplateFlag) return false;
    if (minorChangeFlag && !majorChangeFlag) return true;

    return false;
  };

  const selectFormLineSpan = () => {
    if (isAdmin) {
      return fileList?.length > 0 ? 10 : 12;
    }
    return 12;
  };

  const shouldEnableAddFieldButton = () =>
    !areThereEmptyBoundingBoxes() && !areThereFieldsWithoutName();

  const handleNewEditFormChange = (key: string) => {
    if(isAccessDenied) {
      triggerAccessDeniedModal();
      return;
    }

    if(templateFields.length > 0 && newTemplateFlag ||
      (submitButtonEnabled || saveButtonEnabled || majorChangeFlag)) {
      if(unsavedChanges) {
        setTriggerCallback(() => {
          cleanUp();
          setNewTemplateFlag(() => !newTemplateFlag);
          setActiveKey(key);
        })
        changeTrigger();
      }
      return;
    }

    setNewTemplateFlag(key === 'new' ? true : false);
    setActiveKey(key);
    setSelectedFormId(() => '');
    setMinorChangeFlag(() => false);
    setMajorChangeFlag(() => false);
    dispatch(resetFields());
    dispatch(updateTemplateFields([]));
    dispatch(updateImageSrc(''));
    setAnnotationOnHold(() => null as unknown as Annotation);
    setRecordOnHold(() => null as unknown as Record);
    setFileList([]);
    setUploadedFormPages([]);
    setPageNosToBeScanned([]);
    setMultiFileUrl("");
    defineFormsForm.resetFields([
      DEFINE_FORMS_FORM_NAMES.SELECT_TYPE,
      DEFINE_FORMS_FORM_NAMES.SELECT_FORM,
      DEFINE_FORMS_FORM_NAMES.FORM_NAME
    ]);
  }

  const onClose = () => {
    setSelectedFormId(() => '');
    setMinorChangeFlag(() => false);
    setMajorChangeFlag(() => false);
    dispatch(resetFields());
    dispatch(updateTemplateFields([]));
    dispatch(updateImageSrc(''));
    setAnnotationOnHold(() => null as unknown as Annotation);
    setRecordOnHold(() => null as unknown as Record);
    setFileList([]);
  }

  useEffect(() => {
    if (formsStoreStatus === "in-progress") {
      setTryUploadApi(tryUploadApi + 1);
      setTimeout(() => {
        if (retryStatus) dispatch(uploadForm({...uploadFormParams, formBase64: "", transactionId }));
        else {
          dispatch(updateFormStatus("completed"));
          setFileList([]);
          setTryUploadApi(0);
          api.destroy();
          api.error({
            message: LOCALIZATION.TIMEOUT_ERROR_TITLE,
            description: LOCALIZATION.TIMEOUT_ERROR_DESC,
            placement: 'bottomLeft',
          });
        }
      }, 1000);
    } else if (formsStoreStatus === "completed") {
      setTryUploadApi(0);
      setUploadFormParams({ formBase64: "", imageExt: "", pageNo: '1' });
    }
  }, [formsStoreStatus]);

  useEffect(() => {
    if (tryUploadApi === 1) {
      setTimeout(() => {
        if (formsStoreStatus === "in-progress") {
          dispatch(setRetryStatus(false));
        }
    }, 600000);
    }
  }, [tryUploadApi]);

  /**
   * Will fetch all templates and form types on load
   */
  useEffect(() => {
    cleanUp();
    markSavedChanges();

    setCleanUpCallback(cleanUp);

    // unfortunately, we have to do this, going directly to /define-forms will fail to load reviewer view
    Auth.currentAuthenticatedUser().then((cognitoUser) => {
      const roles = JSON.parse(cognitoUser.attributes['custom:role']);
      if (!roles.includes('admin')) {
        setNewTemplateFlag(() => false);
      }
    });

    if (formsStoreList.length === 0) {
      dispatch(fetchForms());
    }
  }, []);

  /**
   * Populate form types options list
   */
  useEffect(() => {
    setFormTypesOptions(() =>
      formTypesStoreList.map((type) => ({ label: type.type, value: type.type }))
    );
  }, [formTypesStoreList]);

  /**
   * Handles drag and drop
   */
  useEffect(() => {
    if (isAdmin) {
      let start: any;
      let end;
      const container = document.querySelector('.ant-table-tbody');
      const drake = dragula([container as Element], {
        revertOnSpill: true,
        moves: (el, _source, handle) => {
          start = getIndexInParent(el);

          const cellClass: string = handle?.getAttribute('data-icon') || '';
          if (cellClass.indexOf('holder') > -1) {
            return true;
          }

          return false;
        },
      });

      drake.on('drop', (el) => {
        end = getIndexInParent(el);
        handleReorder(start - 1, end - 1);
        markUnsavedChanges();
      });
    }
  }, [isAdmin]);

  /**
   * Does shallow check if template name is unique
   * Does so every time when selected form type or template name is changed
   */
  useEffect(() => {
    shallowCheckIfTemplateNameIsUnique(watchedFormName);
  }, [selectedFormType, watchedFormName]);

  /**
   * Every time content of forms store changes and selected form type changes
   * If new template flag is false, filter templates according to form type and reset selected template
   */
  useEffect(() => {
    if (newTemplateFlag) {
      //
    } else {
      defineFormsForm.resetFields([
        DEFINE_FORMS_FORM_NAMES.SELECT_FORM,
        DEFINE_FORMS_FORM_NAMES.FORM_NAME,
      ]);
      setFilteredFormTemplates(() =>
        formsStoreList
          .filter((form: TemplateOutput) => form.formType === selectedFormType)
          .map((form: TemplateOutput) => ({
            value: form.id,
            label: form.formName,
            status: form.status,
          }))
      );
    }
  }, [formsStoreList, selectedFormType]);

  /**
   * Handles change of error message and displays it in notification
   */
  useEffect(() => {
    if (!isFormNameUnique) {
      api.destroy();
      api.error({
        message: LOCALIZATION.DEFINED_FORM_VALIDATION_ERROR_TITLE,
        description: `${previousErrorMessage}`,
        placement: 'bottomLeft',
      });
    }
  }, [previousErrorMessage]);

  /**
   * If conditions are met, decide if submit button should be enabled or not
   * If new template flag is true, it should be enabled when form type is selected and template name is valid
   */
  useEffect(() => {
    const submitButtonCheck = shouldEnableSubmitButton();
    const saveButtonCheck = shouldEnableSaveButton();
    const addFieldButtonCheck = shouldEnableAddFieldButton();

    setSubmitButtonEnabled(submitButtonCheck);
    setSaveButtonEnabled(saveButtonCheck);
    setAddFieldButtonEnabled(addFieldButtonCheck);
  }, [
    newTemplateFlag,
    majorChangeFlag,
    minorChangeFlag,
    selectedFormType,
    watchedFormName,
    isFormNameUnique,
    templateFields,
  ]);
  /**
   * Handles, in hook, template name changes when user is selecting template to edit
   * If user makes major changes, a copy number is added, or increased, to the end of template name, e.g. "Template Name 1" becomes "Template Name 1 (1)", or "Template Name 1 (2)"
   */
  useEffect(() => {
    if (!newTemplateFlag && majorChangeFlag) {
      defineFormsForm.resetFields([DEFINE_FORMS_FORM_NAMES.FORM_NAME]);
    }
  }, [majorChangeFlag, templateFields]);

  /**
   * Fix for losing current state templateFields in event handler
   */
  useEffect(() => {
    templateFieldsRef.current = templateFields;
  }, [formsStoreRevision]);

  /**
   * Watch for change form name and uppercase it
   * No, surprisingly, it really doesn't get stuck in endless loop. I checked.
   */
  useEffect(() => {
    defineFormsForm.setFieldValue(
      DEFINE_FORMS_FORM_NAMES.FORM_NAME,
      (watchedFormName || '').toUpperCase()
    );
  }, [watchedFormName]);

  /**
   * Error handling
   */
  useEffect(() => {
    if (isAccessDenied) {
      return;
    }

    const errorMessage = formTypesStoreError || formsStoreError;
    if (errorMessage?.length === 0 || errorMessage === null) {
      return;
    }

    api.destroy();
    api.error({
      message: LOCALIZATION.ERROR_OCCURED_TITLE,
      description: LOCALIZATION.ERROR_OCCURED_HTTP_MESSAGE.replace(
        ':ERROR',
        errorMessage as string
      ),
      placement: 'bottomLeft',
    });
    setFileList([]);
    dispatch(resetFormError());
    setFileUploadPercentage(25);
  }, [formTypesStoreError, formsStoreError]);

  useEffect(() => {
    if (imageSrc) {
      setFileUploadPercentage(100);
      setAllTemplateFields(templateFields.filter(item => item.page !== undefined && item.page !== null));
      const tempArr = templateFields?.map(i => (i.pageY || 1));
      setTempHeight(Math.min(...tempArr) || 1);
      setTimeout(() => {
        setFileUploadPercentage(25);
      }, 1500);
    }
  }, [imageSrc]);

  const getScrollYValue = () => {
    let y = '';
    if (newTemplateFlag) y = 'calc(100vh - 520px)';
    else {
      // eslint-disable-next-line no-lonely-if
      if (isAdmin) {
        if (showFormTemplateName) y = 'calc(100vh - 480px)';
        else y = 'calc(100vh - 410px)';
      } else {
        y = 'calc(100vh - 336px)';
      }
    }
    return y;
  }

  return (
    <>
      {contextHolder}
      <PageHeader title="Define Forms">
        <PageLoader spinning={loading} />
        <Spin size="large" spinning={updatingTemplate}>
          <Form
            form={defineFormsForm}
            name="define-forms-form"
            onFinish={doHandleDefineFormsFormSubmit}
            layout="vertical"
          >
            <Row>
              {isAdmin && (
                <Tabs
                  type="card"
                  onChange={handleNewEditFormChange}
                  activeKey={activeKey}
                  items={formTabs.map((i) => ({
                    label: i.label,
                    key: i.id,
                  }))}
                />
              )}
            </Row>
            <Row
              style={{
                padding: '1rem',
                backgroundColor: 'var(--color-primary-02)',
                height: (!isAdmin || isAccessDenied) ? 'calc(100vh - 109px)' : 'calc(100vh - 163px)',
              }}
            >
              <Col span={12}>
                <Row>
                  {/* {isAdmin &&
                  <HeaderCol span={24}>{LOCALIZATION.DEFINE_FORMS_HEADER_TITLE}</HeaderCol>
                } */}
                  {(!isAdmin || isAccessDenied) && (
                    <HeaderCol span={24} style={{ backgroundColor: "transparent", color: "#fff", padding: 0, marginBottom: "10px" }}>
                      {LOCALIZATION.DEFINE_FORMS_HEADER_TITLE}{' '}
                      <span style={{ fontWeight: 'normal' }}>
                        (<RedAsterisk>*</RedAsterisk>{LOCALIZATION.VIEW_ONLY_TEXT})
                      </span>
                    </HeaderCol>
                  )}
                </Row>
                <Row>
                  <StyledCol
                    span={selectFormLineSpan()}
                    className={`${fileList?.length > 0 && 'has-file'} ${
                      (fieldsStoreStatus === 'loading' || fieldsStoreStatus === 'in-progress') && 'uploading'
                    }
                    ${fileList?.length > 0 && imageSrc && 'img-uploaded'}
                    ${!newTemplateFlag && 'edit-form'}`}
                    style={{ paddingTop: 0 }}
                  >
                    {newTemplateFlag && isAdmin && (
                      <>
                        {uploadedFormPages.length === 0 && <Upload
                          id="DefineForms_Upload"
                          maxCount={1}
                          customRequest={handleCustomRequest}
                          // onChange={handleUploadChange}
                          disabled={!isAdmin || isAccessDenied}
                          fileList={fileList}
                          onRemove={cleanUp}
                          accept=".jpg, .jpeg, .png, .pdf"
                          className={`${(fieldsStoreStatus === 'loading'|| fieldsStoreStatus === 'in-progress') && 'uploading-file'
                            }`}
                          // beforeUpload={file => {
                          //   const reader: any = new FileReader();
                          //   reader.readAsBinaryString(file);
                          //   reader.onloadend = () => {
                          //     const count = reader.result?.match(/\/Type[\s]*\/Page[^s]/g).length;
                          //     if (count > 1) {
                          //       let pages: any[] = [];
                          //       for (let i = 1; i <= count; i++) {
                          //         pages = [...pages, i];
                          //       }
                          //       setUploadedFormPages(pages);
                          //     } else {
                          //       handleCustomRequest();
                          //     }
                          //   }
                          // }}
                        >
                          {fileList?.length === 0 && (
                            <UploadButton disabled={!isAdmin || isAccessDenied}>
                              {/* <UploadOutlined style={{ fontSize: '1rem' }} /> */}
                              <ImgDiv className="icon-img" draggable={false} />
                              {LOCALIZATION.DEFINE_FORMS_UPLOAD_BUTTON_LABEL}
                            </UploadButton>
                          )}
                        </Upload>}
                        {(fieldsStoreStatus === 'loading' || fieldsStoreStatus === 'in-progress') &&
                          fileList?.length > 0 && (
                            <Progress
                              percent={fileUploadPercentage}
                              size="small"
                              status="active"
                            />
                          )}
                        {fileList?.length > 0 && (
                          <CloseCircle
                            className="close-circle"
                            onClick={onClose}
                          />
                        )}
                      </>
                    )}
                    {!newTemplateFlag && generateSelectFormTypeItem()}
                  </StyledCol>
                  <FormSelector
                    newTemplateFlag={newTemplateFlag}
                    imageSrc={imageSrc}
                    selectedFormType={selectedFormType}
                    loading={formsStoreStatus === 'loading' || formsStoreStatus === "in-progress"}
                    filteredFormTemplates={filteredFormTemplates}
                    previousErrorMessage={previousErrorMessage}
                    isFormNameUnique={isFormNameUnique}
                    showFormTemplateName={showFormTemplateName}
                    isAdmin={isAdmin}
                    defineFormsForm={defineFormsForm}
                    onFormSelect={onFormSelect}
                    filterSpecialChars={filterSpecialChars}
                    generateSelectFormTypeItem={generateSelectFormTypeItem}
                    onTemplateNameChange={shallowCheckIfTemplateNameIsUnique}
                  />
                </Row>
                {newTemplateFlag && isAdmin && uploadedFormPages?.length > 0 && <>
                  <Row>
                    <p style={{ fontSize: '14px', fontWeight: "bold" }}>
                      <RedAsterisk>*</RedAsterisk>{LOCALIZATION.DEFINE_FORMS_UPLOAD_PAGES_TEXT}
                    </p>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Select
                        mode='multiple'
                        allowClear
                        className="page-select"
                        value={pageNosToBeScanned}
                        onChange={(value) => {
                          if (value && value.includes("all")) {
                            setPageNosToBeScanned(uploadedFormPages);
                          } else {
                            setPageNosToBeScanned(value);
                          }
                        }}
                        style={{ width: "100%" }}
                      >
                        <Option key="all" value="all">ALL</Option>
                        {uploadedFormPages.map((p: any) => (
                          <Option key={p} value={p}>{p}</Option>
                        ))}
                      </Select>
                    </Col>
                    <Col span={4}>
                      <UploadButton onClick={() => analyzeForm()} disabled={pageNosToBeScanned.length === 0} style={{ width: '100%', marginTop: 0 }}>
                        {LOCALIZATION.DEFINE_FORMS_UPLOAD_BUTTON}
                      </UploadButton>
                    </Col>
                  </Row></>}
                <Row>
                  <Col span={24}>
                    <Table
                      id="DefineForms_Table_table"
                      dataSource={templateFields}
                      loading={fieldsStoreStatus === 'loading' || fieldsStoreStatus === 'in-progress'}
                      pagination={false}
                      scroll={{
                        y: getScrollYValue(),
                      }}
                      columns={[
                        {
                          title: '',
                          key: 'dragDrop',
                          render: (_, record: any) =>
                            isAdmin ? (
                              <HolderOutlined
                                className="draggable"
                                type="swap"
                                id={`handle-record-${record.id}`}
                              />
                            ) : (
                              <HolderOutlined
                                style={{ color: 'var(--color-neutral-06)' }}
                              />
                            ),
                          width: '35px',
                          className: 'draggable',
                        },
                        {
                          title: LOCALIZATION.DEFINED_FIELDS_NAME_HEADER,
                          dataIndex: 'name',
                          key: 'name',
                          render: (value: number, record: any) => (
                            <Input
                              style={{
                                color: '#ffffff',
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                borderRadius: '4px',
                              }}
                              value={value}
                              onFocus={() => {
                                if (
                                  !areThereEmptyBoundingBoxes() &&
                                  !areThereFieldsWithoutName()
                                ) {
                                  handleHighlight(record);
                                }
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                if (record.id === selectedRowRecord?.id) {
                                  handleNameChange(record, e);
                                  setMinorChangeFlag(() => true);
                                  markUnsavedChanges();
                                }
                              }}
                              disabled={
                                !isAdmin ||
                                ((areThereEmptyBoundingBoxes() ||
                                  areThereFieldsWithoutName()) &&
                                  record.id !== selectedRowRecord?.id)
                              }
                              onSelect={() => {
                                onRecordSelect(record);
                              }}
                              id={`input-record-${record.id}`}
                            />
                          ),
                          width: '230px',
                        },
                        {
                          title: LOCALIZATION.DEFINED_FIELDS_TYPE_HEADER,
                          dataIndex: 'type',
                          key: 'type',
                          render: (value: string, record: any) => (
                            <Select
                              value={value}
                              onFocus={() => handleHighlight(record)}
                              onSelect={(v: string) => {
                                handleTypeChange(record, v);
                                setMinorChangeFlag(() => true);
                                markUnsavedChanges();
                              }}
                              options={configTypesStoreList.map(
                                (configType: ConfigType) => ({
                                  value: configType.name,
                                  label: configType.display_name,
                                })
                              )}
                              style={{ width: '150px' }}
                              disabled={
                                !isAdmin ||
                                areThereEmptyBoundingBoxes() ||
                                areThereFieldsWithoutName()
                              }
                              virtual={false}
                            />
                          ),
                          width: '160px',
                        },
                        {
                          title: LOCALIZATION.DEFINED_FIELDS_CONFIDENCE_HEADER,
                          dataIndex: 'confidence',
                          key: 'confidence',
                          render: (value: number, record: any) => (
                            <Input
                              style={{
                                color: '#ffffff',
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                borderRadius: '4px',
                              }}
                              value={value}
                              onFocus={() => handleHighlight(record)}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleConfidenceChange(record, e);
                                setMinorChangeFlag(() => true);
                                markUnsavedChanges();
                              }}
                              disabled={
                                !isAdmin ||
                                areThereEmptyBoundingBoxes() ||
                                areThereFieldsWithoutName()
                              }
                              className="confidence-input"
                            />
                          ),
                          width: '120px',
                        },
                        {
                          title: LOCALIZATION.DEFINED_FIELDS_REQUIRED_HEADER,
                          dataIndex: 'isRequired',
                          key: 'isRequired',
                          render: (value: number, record: any) => (
                            <Switch
                              checked={record.isRequired}
                              onChange={() => {
                                handleIsRequiredChange(
                                  record,
                                  !record.isRequired
                                );
                                setMinorChangeFlag(() => true);
                                markUnsavedChanges();
                              }}
                              disabled={
                                !isAdmin ||
                                areThereEmptyBoundingBoxes() ||
                                areThereFieldsWithoutName()
                              }
                            />
                          ),
                          width: '95px',
                        },
                        {
                          title: (
                            <Button
                              id="DefineForms_Table_Button_AddField"
                              type="text"
                              size="small"
                              disabled={
                                !addFieldButtonEnabled || !isAdmin || !imageSrc
                              }
                              onClick={() => showCreateBlankRecordAdvisory()}
                            >
                              <PlusOutlined />
                            </Button>
                          ),
                          width: '50px',
                        },
                      ]}
                      onRow={(record: Record) => ({
                        onClick: () => onRecordSelect(record),
                        onContextMenu: (event) => {
                          event.preventDefault();
                          if (!isAdmin) {
                            return;
                          }
                          if (rowContextMenu.visible === false) {
                            document.addEventListener(
                              `click`,
                              function onClickOutside() {
                                setRowContextMenu(() => ({
                                  visible: false,
                                  x: 0,
                                  y: 0,
                                }));
                                document.removeEventListener(
                                  `click`,
                                  onClickOutside
                                );
                              }
                            );
                          }
                          setRowContextMenu(() => ({
                            visible: true,
                            x: event.clientX,
                            y: event.clientY,
                          }));
                          setRecordOnHold(() => record);
                        },
                      })}
                      rowClassName={(record: Record) =>
                        record && record.isHighlighted ? 'selected-row' : ''
                      }
                      rowKey={(record: Record) => record.id}
                      style={{
                        visibility: showFormTemplateFields ? 'visible' : 'hidden',
                        cursor: 'pointer',
                      }}
                      className="DefineForms-table"
                    />
                  </Col>
                </Row>
                {showFormTemplateFields && isAdmin && (
                  <Row>
                    <StyledCol
                      span={24}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        paddingTop: '15px',
                        marginTop: '3px',
                        // borderTop: 'solid 1px var(--color-neutral-07)',
                        position: 'fixed',
                        bottom: '20px',
                        width: '46%',
                      }}
                    >
                      <Button
                        id="DefineForms_Button_updateTemplate"
                        htmlType="submit"
                        style={{
                          margin: '0 15px',
                          display: newTemplateFlag ? 'none' : 'inherit',
                        }}
                        type="default"
                        disabled={!saveButtonEnabled}
                      >
                        {LOCALIZATION.DEFINE_FORMS_SUBMIT_BUTTON_STATE_1}
                      </Button>
                      <Button
                        id="DefineForms_Button_submitNewTemplate"
                        htmlType="submit"
                        style={{ margin: '0 15px' }}
                        type="primary"
                        disabled={!submitButtonEnabled}
                      >
                        {LOCALIZATION.DEFINE_FORMS_SUBMIT_BUTTON_STATE_2}
                      </Button>
                      {createPortal(
                        <Modal
                          centered
                          title={modalTitle}
                          open={showModal}
                          onOk={() => doHandleDefineFormsFormSubmit()}
                          okText={LOCALIZATION.OK_BUTTON_TITLE}
                          onCancel={() => setShowModal(() => false)}
                          cancelText={LOCALIZATION.CANCEL_BUTTON_TITLE}
                        >
                          <Space style={{ padding: '30px 0' }}>
                            <Typography.Text>{modalText}</Typography.Text>
                          </Space>
                        </Modal>,
                        document.getElementById('modals') as HTMLElement
                      )}
                    </StyledCol>
                  </Row>
                )}
              </Col>
              <Col
                span={12}
                className='define-forms-image-section'
                style={{
                  maxHeight: (!isAdmin || isAccessDenied) ? 'calc(100vh - 140px)' : 'calc(100vh - 194px)',
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundColor: 'var(--color-primary-01)',
                  // padding: '15px',
                  // minHeight: 'calc(100vh - 44px)',
                  // paddingRight: '10px',
                  borderRadius: '10px',
                  border: '5px solid var(--color-extended-01)',
                }}
                ref={boundingBoxRef}
              >
                <Space
                  style={{
                    marginTop: (fieldsStoreStatus === 'loading' || fieldsStoreStatus === 'in-progress') ? '25%' : '0',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Spin size="large" spinning={fieldsStoreStatus === 'loading' || fieldsStoreStatus === 'in-progress'}>
                    <BoundingBoxOverlay
                      id="DefineForms_BoundingBoxOverlay"
                      src={imageSrc}
                      alt=""
                      annotations={templateFields.map((tf: Record) => ({
                        ...tf,
                        geometry: {
                          height: tf.geometry.height * 100 * ((tf.pageY === 1 || !tf.pageY) ? 1 : tempHeight),
                          width: tf.geometry.width * 100,
                          x: tf.geometry.x * 100,
                          y: tf.geometry.y * 100 * (tf.pageY || 1),
                        },
                      }))}
                      value={current}
                      onChange={canDrawBoundingBox() ? onChange : undefined}
                      onSubmit={onSubmit}
                      onCancel={onCancel}
                      onDelete={
                        selectedFormType && isAdmin ? handleDelete : undefined
                      }
                      onClick={onBoxSelection}
                    />
                    {createPortal(
                      <Modal
                        centered
                        title={modalTitle}
                        open={showAdvisoryModal}
                        onOk={() => {
                          markUnsavedChanges();
                          onAdvisoryModalOk();
                          setIfUserWasSureBefore(() => true);
                        }}
                        okText={LOCALIZATION.OK_BUTTON_TITLE}
                        onCancel={() => {
                          setShowAdvisoryModal(() => false);
                        }}
                        cancelText={LOCALIZATION.CANCEL_BUTTON_TITLE}
                      >
                        <Space style={{ padding: '30px 0' }}>
                          <Typography.Text>{modalText}</Typography.Text>
                        </Space>
                      </Modal>,
                      document.getElementById('modals') as HTMLElement
                    )}
                  </Spin>
                  {!showFormTemplateFields && !multiFileUrl &&
                    !imageSrc &&
                    isAdmin &&
                    (fieldsStoreStatus !== 'loading' && fieldsStoreStatus !== 'in-progress') && (
                      <Row>
                        <NoFormMessage>
                          {newTemplateFlag
                            ? LOCALIZATION.NEW_FORM_UPLOAD_MESSAGE
                            : LOCALIZATION.SELECT_FORM_MESSAGE}
                        </NoFormMessage>
                      </Row>
                    )}
                </Space>
                  {!showFormTemplateFields && multiFileUrl &&
                    !imageSrc &&
                    isAdmin &&
                    (fieldsStoreStatus !== 'loading' && fieldsStoreStatus !== 'in-progress') && (
                        <embed src={multiFileUrl} style={{ width: '100%', height: '100%' }} />
                    )}
              </Col>
            </Row>
          </Form>
        </Spin>
        {/* <Row>
        <Col span={12}/>
        <FooterCol span={12}>
          <Typography.Text>Page 1 of 1</Typography.Text>
          <ZoomControl onZoomLevelChange={() => {}} zoomLevelAtStart={100} />
        </FooterCol>
      </Row> */}
        <RowContextMenu
          record={recordOnHold as Record}
          onDelete={(record: Record) => {
            setRecordOnHold(record);
            setHighlightedOnHold(() => true);
            setModalTitle(() => LOCALIZATION.ARE_YOU_SURE_TITLE);
            setModalText(() => LOCALIZATION.DEFINE_FORMS_ADVISORY_DELETE_TEXT);
            if (!newTemplateFlag && !userWasSureBefore) {
              setShowAdvisoryModal(() => true);
            } else {
              handleRowDelete(record);
              setMajorChangeFlag(() => true);
              markUnsavedChanges();
            }
          }}
          visible={rowContextMenu.visible}
          x={rowContextMenu.x}
          y={rowContextMenu.y}
        />
      </PageHeader>
    </>
  );
}

export default DefineForms;
