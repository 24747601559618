import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import { Form, Select, Input, Tooltip, Col, FormInstance } from 'antd';
import {
  CheckOutlined
} from '@ant-design/icons';
import { NamePath } from 'antd/es/form/interface';
import styled from 'styled-components';
import LOCALIZATION from '../../localization';
import { SelectOptions } from '../../interfaces/SelectOptions';
import { DefinedForm } from '../../interfaces/DefinedFormType';
import UnsavedChangesContext from '../../context/UnsavedChanges';


/**
 * An interface with props for FormSelector
 *
 * @interface Props
 * @typedef {Props}
 */
interface Props {
  /** A flag to inform about new template status */
  newTemplateFlag: boolean;
  /** A path to image */
  imageSrc: string | null;
  /** Selected form type */
  selectedFormType: string | null;
  /** A loading flag */
  loading: boolean;
  /** A filtered list of visible form templates */
  filteredFormTemplates: SelectOptions[];
  /** Recent error message */
  previousErrorMessage: string;
  /** Should form template name be shown? */
  showFormTemplateName: boolean;
  /** An indicator if error border should be applied */
  isFormNameUnique: boolean;
  /** Allow only selection, if user is not admin */
  isAdmin: boolean;
  /** A reference to DefinedForm form instance */
  defineFormsForm: FormInstance<DefinedForm>;
  /** A reference to parent component method that handles select of form */
  onFormSelect: () => void;
  /** A reference to parent component method that filters special characters from input */
  filterSpecialChars: (input: string) => string;
  /** A reference to method that generates form type items in select component */
  generateSelectFormTypeItem: () => JSX.Element;
  /** A reference to method in parent component that handles change of template name */
  onTemplateNameChange: (templateName: string) => void;
}

const RedAsterisk = styled.span`
  color: red;
  margin-right: 2px;
`;

const StyledCol = styled(Col)`
  padding-left: 15px;
  padding-top: 5px;
  padding-right: 11px;
`;

const StyledCheckOutlined = styled(CheckOutlined)`
  font-size: 0.8em; 
  margin-right: 3px;
`;

const DEFINE_FORMS_FORM_NAMES: { [key: string]: NamePath } = {
  SELECT_TYPE: 'selectType',
  SELECT_FORM: 'selectTemplate',
  FORM_NAME: 'templateName',
};

/**
 * In DefineForms the form that selects the form type and form template can behave differently according to flags in DefineForms and according to role of user.
 * 
 * For that reason, that form is generated dynamically through this component.
 *
 * @param {{ newTemplateFlag: any; imageSrc: any; selectedFormType: any; loading: any; filteredFormTemplates: any; previousErrorMessage: any; showFormTemplateName: any; isFormNameUnique: any; isAdmin: any; ... 4 more ...; onTemplateNameChange: any; }} {
  newTemplateFlag,
  imageSrc,
  selectedFormType,
  loading,
  filteredFormTemplates,
  previousErrorMessage,
  showFormTemplateName,
  isFormNameUnique,
  isAdmin,
  defineFormsForm,
  onFormSelect,
  filterSpecialChars,
  generateSelectFormTypeItem,
  onTemplateNameChange
}
 * @returns
 */
const FormSelector: React.FC<Props> = ({
  newTemplateFlag,
  imageSrc,
  selectedFormType,
  loading,
  filteredFormTemplates,
  previousErrorMessage,
  showFormTemplateName,
  isFormNameUnique,
  isAdmin,
  defineFormsForm,
  onFormSelect,
  filterSpecialChars,
  generateSelectFormTypeItem,
  onTemplateNameChange
}) => {
  const { markUnsavedChanges, unsavedChanges, setTriggerCallback, changeTrigger, setTriggerModalTexts } = useContext(UnsavedChangesContext)

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const filteredName = filterSpecialChars(event.target.value);
      defineFormsForm.setFieldValue(
        DEFINE_FORMS_FORM_NAMES.FORM_NAME,
        filteredName.toUpperCase(),
      );
      markUnsavedChanges();
    },
    [filterSpecialChars, defineFormsForm],
  );

  const [formTemplateVersion, setFormTemplateVersion] = useState<string>('');
  const { Option } = Select;

  const sortedFormTemplates = filteredFormTemplates.sort((a, b) => {
    const aIsActive = a.status.toLowerCase() === 'active';
    const bIsActive = b.status.toLowerCase() === 'active';
  
    if (aIsActive === bIsActive) {
      return a.label.localeCompare(b.label);
    }
    
    if (aIsActive) {
      return -1;
    }
  
    return 1;
  });

  return (
    <>
      <StyledCol span={newTemplateFlag ? 14 : 12} style={{ paddingTop: 0 }}>
        {newTemplateFlag && imageSrc && generateSelectFormTypeItem()}
        {!newTemplateFlag && selectedFormType && (
          <Form.Item
            label={
              <>
                {isAdmin && <RedAsterisk>*</RedAsterisk>}
                {LOCALIZATION.DEFINE_FORMS_SELECT_TEMPLATE_VERSION}
              </>
            }
            name={DEFINE_FORMS_FORM_NAMES.SELECT_FORM}
          >
            <Select
              id={`DefineForms_FormSelector_Select_${DEFINE_FORMS_FORM_NAMES.SELECT_FORM}`}
              virtual={false}
              showSearch={false}
              placeholder={LOCALIZATION.DEFINE_FORMS_SELECT_LABEL}
              size="middle"
              searchValue={formTemplateVersion}
              onSearch={(value: string) =>
                setFormTemplateVersion(filterSpecialChars(value))
              }
              style={{ width: '99%' }}
              loading={loading}
              filterOption={false}
              onChange={() => {
                if (unsavedChanges) {
                  setTriggerCallback(() => {
                    onFormSelect();
                  });
                  setTriggerModalTexts(
                    LOCALIZATION.DISCARD_CHANGES_MODAL_TITLE,
                    LOCALIZATION.DISCARD_CHANGES_MODAL_MESSAGE,
                    LOCALIZATION.DISCARD_CHANGES_MODAL_OK_BUTTON,
                    LOCALIZATION.CANCEL_BUTTON_TITLE
                  );
                  changeTrigger();
                } else {
                  onFormSelect();
                }
              }}
              disabled={newTemplateFlag}
            >
              {sortedFormTemplates.map((template) => (
                <Option key={template.value} value={template.value}>
                  {template.status.toLowerCase() === 'active' && (
                    <StyledCheckOutlined />
                  )}
                  {template.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </StyledCol>
      <StyledCol span={showFormTemplateName ? 24 : 0} style={{ padding: '0px 4px 0 0' }}>
        <Tooltip title={previousErrorMessage}>
          <Form.Item
            label={
              <>
                {isAdmin && <RedAsterisk>*</RedAsterisk>}
                {newTemplateFlag
                  ? LOCALIZATION.DEFINE_FORMS_INPUT_LABEL
                  : LOCALIZATION.DEFINE_FORMS_NEW_TEMPLATE_VERSION}
              </>
            }
            name={DEFINE_FORMS_FORM_NAMES.FORM_NAME}
            // Fixing the issue with form name validation when the form is not visible
            className={showFormTemplateName ? '' : 'invisible'}
          >
            <Input
              id={`DefineForms_FormSelector_Input_${DEFINE_FORMS_FORM_NAMES.FORM_NAME}`}
              autoComplete="off"
              style={{ width: '99%' }}
              placeholder={
                newTemplateFlag
                  ? LOCALIZATION.DEFINE_FORMS_INPUT_LABEL
                  : LOCALIZATION.DEFINE_FORMS_NEW_TEMPLATE_VERSION
              }
              size="middle"
              status={isFormNameUnique ? '' : 'error'}
              disabled={!isAdmin}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleInputChange(event);
                onTemplateNameChange(event.target.value);
              }}
              value={DEFINE_FORMS_FORM_NAMES.FORM_NAME}
            />
          </Form.Item>
        </Tooltip>
      </StyledCol>
    </>
  );
};

export default FormSelector;
