/* eslint-disable no-unsafe-optional-chaining */
import { Button, Col, Form, Row, Select, Space, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import { NamePath } from "antd/es/form/interface";
import { func } from "prop-types";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';
import { SearchScansParams } from "../../../api/scansApi.service";
import FullWidthSpace from "../../../components/styled/FullWidthSpace";
import LOCALIZATION from '../../../localization';
import { AppDispatch, RootState } from "../../../store/store";
import { fetchForms } from "../../../store/forms/formsReducer";
import { resetPaginationDetails } from "../../../store/scans/scansReducer";
import AccessDeniedContext from "../../../context/AccessDenied";
import DateSelector from "../../../components/DateSelector/DateSelector";

dayjs.extend(timezone);
dayjs.extend(utc);

moment.tz.setDefault(dayjs.tz.guess());
dayjs.tz.setDefault(dayjs.tz.guess());

/**
 * Interface defining props for SearchForm
 * 
 * @interface
 */
interface SearchFormProps {
    /**
    * A reference to method in parent component which do following action after form is submitted.
    * 
    * @param {any} values An object with search values, like form type, scan date range and status
    */
    onFormSubmit: (values: any) => void;
}
   
const FIND_SCANS_FORM_NAMES: { [key: string]: NamePath } = {
    SELECT_FORM_TYPE: 'selectedTemplateType',
    SELECT_FORM: 'selectedFormId',
    SCAN_DATE_START: 'scanDateFrom',
    SCAN_DATE_END: 'scanDateTo',
    SCAN_DATE_RANGE: 'scanDateRange',
    UNRESOLVED_ISSUES: 'unresolvedIssues',
    ISSUE_STATUS: 'issueStatus',
    FORM_STATUS: 'formStatus',
}

/**
 * SearchForm is FindScans's child component with Antd components to set values for scans search.
 * 
 * Relies on form types store, which list populates form type dropdown component.
 * 
 * This component is using props to exchange data and actions with parent component.
 * 
 * FindScans might call cleanUp method, which, among other activities, emits an event "findScansCleanUp".
 * SearchForm listens on this event and triggers it's own cleanUp method.
 * 
 * @param {SearchFormProps} param0 
 * @returns {React.Component}
 */
function SearchForm({
    onFormSubmit
}: SearchFormProps) {
    const [formInstance] = Form.useForm<SearchScansParams>();

    const formTypesStoreList = useSelector((state: RootState) => state.formTypes.list);
    const formTypesStoreStatus = useSelector((state: RootState) => state.formTypes.status);
    const [formType, setFormType] = useState("");

    const { isAccessDenied } = useContext(AccessDeniedContext);

    const dispatch = useDispatch<AppDispatch>();
    const formStatusDefaultValues = LOCALIZATION.FORM_STATUS_DEFAULT_SELECTED;
    const [formStatusValues, setFormStatusValues] = useState<string[]>(formStatusDefaultValues);
    const undefinedFormTemplate = LOCALIZATION.UNDEFINED_FORM_TEMPLATE_KEY;

    const maxTagCount = window.innerWidth > 1402 ? 2 : 1

    /**
     * Clean up method
     * Will reset component to the default state
     * 
     * @public
     */
    const cleanUp = () => {
      formInstance.resetFields();

      if(formTypesStoreList.length === 0) {
          dispatch(fetchForms());
      }
      formInstance.setFieldValue(FIND_SCANS_FORM_NAMES.FORM_STATUS, formStatusDefaultValues);
      setFormStatusValues(formStatusDefaultValues);
    }
    
    const onChangeFormType = (val: any) => {
      setFormType(val);
      if (val === undefinedFormTemplate) {
        formInstance.setFieldValue(FIND_SCANS_FORM_NAMES.FORM_STATUS, []);
      } else if (formType === undefinedFormTemplate && val !== undefinedFormTemplate) {
        formInstance.setFieldValue(FIND_SCANS_FORM_NAMES.FORM_STATUS, formStatusDefaultValues);
        setFormStatusValues(formStatusDefaultValues);
      }
    }

    const otherSelectedStatusValues = formStatusValues?.slice(
      maxTagCount,
      formStatusValues?.length
    );

    const otherSelectedStatus = (
      <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
        {otherSelectedStatusValues?.map((i: string) => (
          <li style={{ padding: '5px' }} key={i}>
            {i}
          </li>
        ))}
      </ul>
    );

    const getMaxTagPlaceholder = (
      <Tooltip title={otherSelectedStatus} placement="bottomLeft">
        <div className="status-rest-count">
          <span>
            +{' '}
            {formStatusValues?.length - maxTagCount}
          </span>
        </div>
      </Tooltip>
    );

    /**
     * At the beginning run clean up and set reference to listener for "findScansCleanUp" event from FindScans component
     * 
     * @public
     */
    useEffect(() => {
      cleanUp();

      document.addEventListener('findScansCleanUp', cleanUp)
    }, []);

    /**
     * Listen to changes in form types store list
     * If changed and not empty, select the first value
     * 
     * @public
     */
    useEffect(() => {        
      if (formTypesStoreList.length > 0 && !formInstance.getFieldValue(FIND_SCANS_FORM_NAMES.SELECT_FORM_TYPE)) {
          formInstance.setFieldValue(FIND_SCANS_FORM_NAMES.SELECT_FORM_TYPE, formTypesStoreList[0].type);
          setFormType(formTypesStoreList[0].type);
        }
    }, [formTypesStoreList]);

    return (
        <Form 
            form={formInstance}
            name="search-scans-form" 
            onFinish={(values:any) => {
              dispatch(resetPaginationDetails());
              onFormSubmit(values);
            }}
            layout="vertical"
        >
        <Row>
          <Col span={7}>
            <FullWidthSpace>
              <Form.Item label={LOCALIZATION.SELECT_FORM_TYPE_LABEL} name={FIND_SCANS_FORM_NAMES.SELECT_FORM_TYPE} rules={[{ required: true, message: LOCALIZATION.SELECT_FORM_TYPE_WARNING }]}>
                <Select 
                  id={`FindScans_SearchForm_Select_${FIND_SCANS_FORM_NAMES.SELECT_FORM_TYPE}`}
                  placeholder={LOCALIZATION.SELECT_FORM_TYPE_LABEL}
                  onChange={onChangeFormType}
                  options={[
                    { label: `[${LOCALIZATION.UNDEFINED_FORM_TEMPLATE_LABEL}]`, value: undefinedFormTemplate}, 
                    ...formTypesStoreList.map(type => ({label: type.type, value: type.type}))]}
                  loading={formTypesStoreStatus === 'loading'}
                  virtual={false}
                  size="large"
                />
              </Form.Item>
            </FullWidthSpace>
          </Col>
          <Col span={4} xxl={{span:6}}>
            <FullWidthSpace>
              <Form.Item label={LOCALIZATION.SELECT_SCAN_DATE_RANGE_LABEL} name={FIND_SCANS_FORM_NAMES.SCAN_DATE_RANGE} rules={[{ required: false, message: LOCALIZATION.SELECT_SCAN_DATE_RANGE_WARNING }]}>
                <DateSelector/>
              </Form.Item>
            </FullWidthSpace>
          </Col>
          <Col span={7}>
            <FullWidthSpace>
              <Form.Item label={LOCALIZATION.FORM_STATUS_LABEL} name={FIND_SCANS_FORM_NAMES.FORM_STATUS}>
                <Select
                  id={`FindScans_SearchForm_Select_${FIND_SCANS_FORM_NAMES.FORM_STATUS}`}
                  mode="multiple"
                  placeholder=""
                  defaultValue={formStatusDefaultValues}
                  disabled={formType === undefinedFormTemplate}
                  onChange={(val: string[]) => setFormStatusValues(val.sort())}
                  optionLabelProp="label"
                  size="large"
                  maxTagCount={maxTagCount}
                  maxTagPlaceholder={getMaxTagPlaceholder}
                  value={formStatusValues}
                >
                  {
                    LOCALIZATION.FORM_STATUS_OPTIONS.map((option) => (
                      <Select.Option value={option} label={option}>
                        <Space>
                          {option}
                        </Space>
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </FullWidthSpace>                    
          </Col>
          <Col span={4}>
            <FullWidthSpace>
              <Button 
                id="FindScans_SearchForm_Button_submit"
                type="primary" 
                htmlType="submit" 
                style={{ marginTop: '25px', width: '200px'}}
                disabled={isAccessDenied}
                size="large"
              >{LOCALIZATION.FIND_SCANS_BUTTON_LABEL}</Button>                        
            </FullWidthSpace>                                        
          </Col>
        </Row>
      </Form>
    )
}

SearchForm.defaultProps = {
  onFormSubmit: (values: any) => console.log('onFormSubmit: ', values)
}

SearchForm.propTypes = {
  onFormSubmit: func    
}

/**
 * 
 */
export default SearchForm;
